import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import './VideoCarousel.css';

const VideoCarousel = ({ videos }) => {
  return (
    <div className="carousel-wrapper">
      <Carousel
        showThumbs={false}
        showStatus={false}
        infiniteLoop
        useKeyboardArrows
        autoPlay
        interval={5000}
        stopOnHover
      >
        {videos.map((video, index) => (
          <div key={index}>

            <img src={`https://api.judah.tv/uploads/${video.thumbnail}`} alt={video.title} />
            <p className="legend">{video.title}</p>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default VideoCarousel;










