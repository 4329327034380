import React from 'react';

import {
  parseISO,
  formatDistance, isAfter,
} from 'date-fns';

import { ptBR } from 'date-fns/locale';

import './PgLivesItem.css'

import { MdVisibility } from "react-icons/md";

import personagem from '../img/person.png'

import { global } from '../DataAPI/global'
///(item.online) ? `https://live.judah.tv/screenshot/live/${item._id}.jpg?random_number=${Math.random()}` : 
//     <img className="lifoto  " src={(item.online) ? `https://live.soundclub.live/screenshot/live/${item._id}.jpg?random_number=${Math.random()}` : `${global.brl.REACT_APP_FOTO}${item.fotoid}.jpg`} onError={(e) => { e.target.onerror = null; e.target.src = personagem }} alt="foto" />

function PgLivesItem({ item }) {
  return (
    <div className="lifundo">

      <div className="liconteudo cbaixo2" style={{
        backgroundImage: `url( ${+ (item.fototv === '') ? `${global.brl.REACT_APP_FOTO}${item.fotoid}.jpg` : `${global.brl.REACT_APP_FOTO}${item.fototv}.jpg`})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
      }}>

      </div>


      <div className="lfc lfcenter f ldx  ">
        <div className="lfc ">
          <label className=" lilabel">{item.username}</label>

        </div>



        {(item.online) ? (<>
          <div className="lfr lfcenter">
            <div >
              {(item.paga) ? (<p className="lp tvermelho">PAGA</p>) : (<p className="lp tverde">FREE</p>)}
            </div>
            <div className="lfr lfcenter">
              <MdVisibility className="ico" size="1rem" />
              <p className="lp">{item.nrviwers}</p>
            </div>
          </div>
        </>
        ) : (
          <div className="lfr lfcenter">
            {(item.linkplay === '') ? (item.mostrar) ? (item.linkultimo === '') ? <p className=" lp tcinza" >OFF LINE</p> : <p className="lp tazul">Última Live</p> : <p className="lp tcinza">OFF LINE</p> : <p className="lp tamarelo">On Demand</p>}
            <div>
              {(item.proximalive) ? (
                (isAfter(parseISO(item.proximalive), new Date()) ? (
                  <p className="lp">{formatDistance(parseISO(item.proximalive), new Date(), { locale: ptBR, addSuffix: false, includeSeconds: false }).replace("cerca de ", "")}</p>
                ) : <>
                </>)) : (<>
                </>)}
            </div>
          </div>)}
      </div>

    </div>



  );
}

export default PgLivesItem;