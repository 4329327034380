import React, { useEffect, useState, useContext, useRef } from 'react';

import './PgAgenda.css';

import { validarME } from '../DataAPI/apiMe'


import { MeuContexto } from "../App"

function PgAgenda() {

  const [isloading, setisloading] = useState(true);
  const { setlogado, setusername } = useContext(MeuContexto)

  const agendas = [
    {
      id: 1,
      foto: 'https://api.judah.tv/uploads/casamentom.jpg',
      diaSemana: 'Terça-feira',
      horario: '19:30 - 22:00',
    },
    {
      id: 2,
      foto: 'https://api.judah.tv/uploads/favoritam.jpg',
      diaSemana: 'Sexta-feira',
      horario: '19:30 - 22:00',
    },
    {
      id: 3,
      foto: 'https://api.judah.tv/uploads/detonaltasm.jpg',
      diaSemana: 'Domingo',
      horario: '18:00 - 21:00',
    },
    // Adicione mais itens conforme necessário
  ];

  useEffect(
    () => {
      _carregatela()
    }, [])

  async function _carregatela() {


    const me = await validarME.me()
    if (me) {

      setusername(me.username)

      setisloading(false)

      setlogado(true)


    } else {

      setisloading(false)
      setlogado(false)

    }


  }


  return (
    <div className="xtudol sobre">


      <div className="container mx-auto p-4">
        <h1 className="text-2xl font-bold mb-4">Lista de Agendas</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {agendas.map((agenda) => (
            <div
              key={agenda.id}
              className="bg-white rounded-lg shadow-md p-4 flex flex-col items-center"
            >
              <img
                src={agenda.foto}
                alt={`Foto da agenda ${agenda.id}`}
                className="w-32 h-32 rounded-full mb-4  object-cover"
              />
              <h2 className=" text-black text-xl font-semibold mb-2">{agenda.diaSemana}</h2>
              <p className="text-gray-600">{agenda.horario}</p>
            </div>
          ))}
        </div>
      </div>

    </div>

  );
}

export default PgAgenda;