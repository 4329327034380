import React from 'react';

import { global } from '../DataAPI/global'
import './PgaSuporte.css';

function PgaSuporte() {
    /// console.log(global.brl.REACT_APP_EVIPPLAY2ini)
    return (
        <div className={"xtudol suporte"}>

            <h1 className={"Tituloa2"}>Tutorial de uso</h1>
            <br></br>

            <div className="SpGrid">
                <div>
                    <video width="200" controls autoPlay controlsList="nodownload">
                        <source src={`https://api.judah.tv/rec/tela1.mp4`} type="video/mp4" />
                    </video>
                    <h4>Como baixar o aplicativo</h4>
                    <br></br>

                </div>

                <div>


                    <video width="200" controls controlsList="nodownload">
                        <source src={`https://api.judah.tv/rec/tela2.mp4`} type="video/mp4" />
                    </video>
                    <h4>Como acessar e criar conta</h4>
                    <br></br>

                </div>

                <div>


                    <video width="200" controls controlsList="nodownload">
                        <source src={`https://api.judah.tv/rec/tela3.mp4`} type="video/mp4" />
                    </video>
                    <h4>Explorando o SMIZE TV</h4>
                    <br></br>

                </div>

                <div>


                    <video width="200" controls controlsList="nodownload">
                        <source src={`https://api.judah.tv/rec/tela4.mp4`} type="video/mp4" />
                    </video>
                    <h4>Configuração de conta</h4>
                    <br></br>

                </div>

                <div>


                    <video width="200" controls controlsList="nodownload">
                        <source src={`https://api.judah.tv/rec/tela5.mp4`} type="video/mp4" />
                    </video>
                    <h4>Como assistir às lives</h4>
                    <br></br>

                </div>

            </div>
            <br></br>

            <div className="SpContato">
                <p>Caso tenha alguma dúvida, entre em contato no email</p>
                <br></br>

                <a className="Spa" href="mailto:contato@smize.tv?cc=contato@smize.tv&Subject=Suporte">contato@smize.tv</a>

                <p>ou criar um canal ligue</p>
                <p>Ivan Adelino (11) 99981-3774</p>
                <p>ivan@bascode.com</p>
            </div>


        </div>

    );
}

export default PgaSuporte;