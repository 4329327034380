import axios from 'axios';

import { global } from './global'


const api = axios.create({ baseURL: global.brl.REACT_APP_EIPNODEAPI });


api.interceptors.request.use((config) => {

  const headers = { ...config.headers };
  if (global.token) {
    headers.Authorization = `Bearer ${global.token}`;
  };
  return { ...config, headers };
})

export default api;
