import React from 'react';

import './PgaPoliticas.css';

function PgaPoliticas() {
	return (
		<div class="xtudo politica" >



			<h1 style={{ textAlign: 'center' }}>POL&Iacute;TICA DE PRIVACIDADE da PLATAFORMA &ldquo;SMIZE TV&rdquo; (&ldquo;POL&Iacute;TICA DE PRIVACIDADE&rdquo;)</h1>

			<br></br>
			<p>&Uacute;ltima atualiza&ccedil;&atilde;o: 09/06/2023</p>

			<br></br>
			<p>SUM&Aacute;RIO:</p>

			<br></br>

			<ol class="list-root">
				<li>GERAL</li>
				<li>INFORMA&Ccedil;&Otilde;ES COLETADAS PELO SMIZE TV</li>
				<li>FINALIDADES DO TRATAMENTO</li>
				<li>COMPARTILHAMENTO DE DADOS PESSOAIS</li>
				<li>TRANSFER&Ecirc;NCIA INTERNACIONAL DE DADOS PESSOAIS</li>
				<li>DURA&Ccedil;&Atilde;O DO TRATAMENTO</li>
				<li>DIREITOS DOS USU&Aacute;RIOS</li>
				<li>MEDIDAS DE SALVAGUARDA E SEGURAN&Ccedil;A</li>
				<li>MODIFICA&Ccedil;&Otilde;ES DA POL&Iacute;TICA DE PRIVACIDADE</li>
				<li>OUTRAS PLATAFORMAS, APLICATIVOS e/ou APPS</li>
				<li>BEACONS DA WEB</li>
				<li>INFORMA&Ccedil;&Atilde;O P&Uacute;BLICA</li>
				<li>DESATIVA&Ccedil;&Atilde;O DA CONTA</li>
				<li>CONTATO</li>
			</ol>

			<p>&nbsp;</p>

			<p>&nbsp;</p>

			<p>&nbsp;</p>

			<ol class="list-root">
				<li>GERAL:

					<ol>
						<li>aplicativo (&quot;SMIZE TV&quot;) considera a privacidade da informa&ccedil;&atilde;o um assunto de extrema relev&acirc;ncia. Este documento de POL&Iacute;TICA DE PRIVACIDADE foi concebido para comunicar nossas pr&aacute;ticas relativas &agrave; coleta e tratamento de informa&ccedil;&otilde;es que qualquer pessoa (&quot;USU&Aacute;RIO&quot;) possa fornecer atrav&eacute;s do referido aplicativo, especialmente no tocante &agrave; privacidade e aos dados pessoais capazes de identificar o USU&Aacute;RIO, incluindo, sem limita&ccedil;&atilde;o, nome e sobrenome do USU&Aacute;RIO, endere&ccedil;o de e-mail, CPF e qualquer outra informa&ccedil;&atilde;o que o USU&Aacute;RIO escolher fornecer (&quot;Dados Pessoais&quot;).</li>
						<li>USU&Aacute;RIO dever&aacute; ler integralmente esta POL&Iacute;TICA DE PRIVACIDADE antes de utilizar ou submeter qualquer informa&ccedil;&atilde;o por meio do SMIZE TV. Esta POL&Iacute;TICA DE PRIVACIDADE abrange quaisquer formas de tratamento dos Dados Pessoais coletados quando o USU&Aacute;RIO estiver acessando ou utilizando o SMIZE TV e/ou quaisquer SERVI&Ccedil;OS oferecidos ou disponibilizados pelo SMIZE TV por meio do SMIZE TV ou de qualquer outra forma (&quot;SERVI&Ccedil;OS&quot;).</li>
						<li>Para acessar e usar o SMIZE TV e/ou os SERVI&Ccedil;OS, o USU&Aacute;RIO dever&aacute; ter mais de 12 (doze) anos de idade. O USU&Aacute;RIO menor de 12 (doze) anos apenas poder&aacute; acessar e usar o SMIZE TV mediante autoriza&ccedil;&atilde;o espec&iacute;fica de pelo menos um dos pais ou do respons&aacute;vel legal. O SMIZE TV reserva-se o direito de solicitar prova de idade de qualquer USU&Aacute;RIO, a qualquer momento, para verificar se o acesso e uso est&aacute; de acordo com os termos desta POL&Iacute;TICA DE PRIVACIDADE, bem como de cancelar quaisquer contas que n&atilde;o estejam.</li>
						<li>Ao acessar e utilizar o SMIZE TV, o USU&Aacute;RIO concorda com esta POL&Iacute;TICA DE PRIVACIDADE e autoriza o tratamento das informa&ccedil;&otilde;es (incluindo Dados Pessoais) nos termos da presente POL&Iacute;TICA DE PRIVACIDADE, a qual observa a legisla&ccedil;&atilde;o aplic&aacute;vel (incluindo as Leis n&ordm;s 12.965/2014 &ndash; &quot;Marco Civil da Internet&quot; e 13.709/18 &ndash; &quot;Lei Geral de Prote&ccedil;&atilde;o de Dados Pessoais&quot; ou &quot;LGPD&quot;).</li>
						<li>USU&Aacute;RIO que n&atilde;o aceitar qualquer disposi&ccedil;&atilde;o desta POL&Iacute;TICA DE PRIVACIDADE, n&atilde;o dever&aacute; usar o SMIZE TV e/ou quaisquer SERVI&Ccedil;OS oferecidos por ele;</li>
						<li>Os SERVI&Ccedil;OS disponibilizados pelo SMIZE TV podem ser fornecidos em uma variedade de m&iacute;dias e dispositivos conhecidos no momento ou que venham a ser desenvolvidos no futuro incluindo aplica&ccedil;&otilde;es m&oacute;veis, ferramentas de consulta, aplica&ccedil;&otilde;es de refer&ecirc;ncia e conte&uacute;do personalizado;</li>
						<li>USU&Aacute;RIO reconhece que o SMIZE TV poder&aacute; coletar Dados Pessoais e concorda com o tratamento e compartilhamento desses dados nos termos desta POL&Iacute;TICA DE PRIVACIDADE.</li>
						<li>Esta POL&Iacute;TICA DE PRIVACIDADE &eacute; aplic&aacute;vel somente para a PLATAFORMA que o SMIZE TV licencia, e n&atilde;o a quaisquer outras PLATAFORMAs, aplicativos e/ou APPs que o USU&Aacute;RIO possa ser capaz de acessar, inclusive atrav&eacute;s de parceiros, cada um dos quais podem ter a coleta de dados, armazenamento e pr&aacute;ticas de uso e pol&iacute;ticas que diferem materialmente desta POL&Iacute;TICA DE PRIVACIDADE.</li>
						<li>uso da PLATAFORMA &eacute; regido por esta POL&Iacute;TICA DE PRIVACIDADE e pelos TERMOS DE USO.</li>
					</ol>
				</li>
				<li>INFORMA&Ccedil;&Otilde;ES COLETADAS PELO SMIZE TV:
					<ol>
						<li>SMIZE TV poder&aacute; coletar e tratar as seguintes informa&ccedil;&otilde;es (incluindo Dados Pessoais) fornecidas pelos USU&Aacute;RIOS:
							<ol>
								<li>No caso do cadastro do perfil no SMIZE TV:
									<div style={{ margin: '1em' }}>
										<p>Login e senha, sendo o login o endere&ccedil;o de e-mail do USU&Aacute;RIO;</p>
										<p>E-mail e senha da rede social FACEBOOK do USU&Aacute;RIO.</p>
									</div>
								</li>
								<li>No caso do USU&Aacute;RIO ser P&Uacute;BLICO:
									<div style={{ margin: '1em' }}>
										<p>Foto de perfil;</p>
										<p>Nome;</p>
										<p>E-mail;</p>
										<p>Descri&ccedil;&atilde;o do perfil;</p>
										<p>Estilo musical.</p>
									</div>
								</li>
								<li>No caso do USU&Aacute;RIO ser ARTISTA:
									<div style={{ margin: '1em' }}>
										<p>Foto do perfil;</p>
										<p>Nome do artista ou banda;</p>
										<p>E-mail;</p>
										<p>Descri&ccedil;&atilde;o do artista ou banda;</p>
										<p>Estilo musical;</p>
										<p>CPF;</p>
										<p>T&iacute;tulo da live que o ARTISTA ir&aacute; realizar;</p>
										<p>Escolha pelo ARTISTA se a live ser&aacute; free ou paga;</p>
										<p>Data da pr&oacute;xima live.</p>
									</div>
								</li>
								<li>Eventuais Dados Pessoais contidos em comunica&ccedil;&otilde;es trocadas entre o USU&Aacute;RIO e o SMIZE TV ou entre os USU&Aacute;RIOS por e-mail ou por meio do SMIZE TV ou dos SERVI&Ccedil;OS (inclusive, sem limita&ccedil;&atilde;o, coment&aacute;rios postados no SMIZE TV), bem como em respostas dos USU&Aacute;RIOS a pesquisas e/ou d&uacute;vidas que o SMIZE TV possa vir a enviar para fins de melhorar o SMIZE TV e/ou os SERVI&Ccedil;OS ou esclarecer quest&otilde;es ou d&uacute;vidas objeto de investiga&ccedil;&atilde;o interna;</li>
							</ol>
						</li>
						<li>SMIZE TV coletar&aacute; automaticamente e tratar&aacute; as seguintes informa&ccedil;&otilde;es (incluindo Dados Pessoais):
							<ol>
								<li>Os detalhes das visitas ao SMIZE TV e dos recursos que o USU&Aacute;RIO tenha utilizado para acessar o SMIZE TV;</li>
								<li>Informa&ccedil;&otilde;es de registro de quaisquer arquivos carregados no SMIZE TV pelos USU&Aacute;RIOS;</li>
								<li>Informa&ccedil;&otilde;es preferenciais sobre como os USU&Aacute;RIOS do SMIZE TV utilizam os SERVI&Ccedil;OS, as prefer&ecirc;ncias manifestadas e as configura&ccedil;&otilde;es escolhidas. Em alguns casos, para tanto, fazemos uso de cookies, pixel tags e tecnologias similares que criam e mant&ecirc;m identificadores exclusivos;</li>
								<li>Informa&ccedil;&otilde;es do dispositivo m&oacute;vel, incluindo, por exemplo, modelo do hardware, sistema operacional e vers&atilde;o, nomes e vers&otilde;es dos arquivos, idioma de prefer&ecirc;ncia, identificador exclusivo do dispositivo, identificadores de publicidade, n&uacute;mero de s&eacute;rie, informa&ccedil;&otilde;es referentes ao movimento do dispositivo e informa&ccedil;&otilde;es de rede;</li>
								<li>Dados das atividades geradas dentro do SMIZE TV, como, por exemplo, dados relativos &agrave;s LIVES exibidas;</li>
								<li>Informa&ccedil;&otilde;es de acesso registradas no servidor que podem incluir informa&ccedil;&otilde;es como endere&ccedil;o IP do dispositivo, acesso a datas e hor&aacute;rios, caracter&iacute;sticas do SMIZE TV ou p&aacute;ginas visualizadas, bloqueio de aplicativos e outras atividades do sistema, tipo de navegador e SMIZE TV de terceiros ou servi&ccedil;o utilizado antes de interagir com os SERVI&Ccedil;OS;</li>
								<li>Tamb&eacute;m poderemos receber informa&ccedil;&otilde;es (inclusive Dados Pessoais) de outras fontes e associ&aacute;-las &agrave;s informa&ccedil;&otilde;es reunidas atrav&eacute;s do SMIZE TV e/ou dos SERVI&Ccedil;OS. Por exemplo, se o USU&Aacute;RIO utilizar um outro aplicativo que usa nosso API (Application Programming Interface) (ou cujo API usamos), poderemos receber informa&ccedil;&otilde;es sobre o USU&Aacute;RIO e/ou suas conex&otilde;es a partir do SMIZE TV ou do referido aplicativo; NESTE SENTIDO, O USU&Aacute;RIO RECONHECE E ACEITA QUE AO UTILIZAR UM OUTRO APLICATIVO QUE UTILIZA NOSSO API (OU CUJO API USAMOS), PODEREMOS RECEBER INFORMA&Ccedil;&Otilde;ES SOBRE O USU&Aacute;RIO E/OU SUAS CONEX&Otilde;ES A PARTIR DO SMIZE TV OU DO REFERIDO APLICATIVO.
									<ol>
										<li>SMIZE TV E/OU OS SERVI&Ccedil;OS PODEM INTEGRAR RECURSOS DE COMPARTILHAMENTO SOCIAL E OUTRAS FERRAMENTAS RELACIONADAS, QUE LHE PERMITEM COMPARTILHAR A&Ccedil;&Otilde;ES RELACIONADAS AO SMIZE TV E AOS SERVI&Ccedil;OS COM OUTROS APLICATIVOS OU M&Iacute;DIAS E VICE-VERSA. O USO DE TAIS RECURSOS PERMITE O COMPARTILHAMENTO DE INFORMA&Ccedil;&Otilde;ES COM SEUS AMIGOS E/OU O P&Uacute;BLICO, DEPENDENDO DAS CONFIGURA&Ccedil;&Otilde;ES ESTABELECIDAS COM O SERVI&Ccedil;O DE COMPARTILHAMENTO SOCIAL. O USU&Aacute;RIO DEVER&Aacute; CONSULTAR AS POL&Iacute;TICAS DE PRIVACIDADE PERTINENTES RELACIONADAS AOS SERVI&Ccedil;OS DE COMPARTILHAMENTO SOCIAL PARA OBTER MAIS INFORMA&Ccedil;&Otilde;ES SOBRE COMO ADMINISTRAM OU COMPARTILHAM OS DADOS PESSOAIS FORNECIDOS.</li>
									</ol>
								</li>
							</ol>
						</li>
						<li>Informa&ccedil;&otilde;es (incluindo Dados Pessoais) coletadas de forma autom&aacute;tica s&atilde;o utilizadas para diagnosticar problemas no servidor do SMIZE TV, para detectar informa&ccedil;&otilde;es sobre o n&iacute;vel de uso do SMIZE TV e/ ou dos SERVI&Ccedil;OS e para determinar o caminho mais r&aacute;pido que o computador pode tomar para se conectar ao SMIZE TV, bem como para administrar e melhorar o SMIZE TV, o SMIZE TV e os SERVI&Ccedil;OS.</li>
						<li>Algumas informa&ccedil;&otilde;es (inclusive Dados Pessoais) dos USU&Aacute;RIOS s&atilde;o coletadas e utilizadas de forma anonimizada para a gera&ccedil;&atilde;o de estat&iacute;sticas de uso do SMIZE TV e/ou dos SERVI&Ccedil;OS, tais como:
							<ol>
								<li>Abertura da tela do perfil detalhado dos ARTISTAS;</li>
								<li>Abertura de tela do perfil detalhado do P&Uacute;BLICO.</li>
								<li>Abertura da tela das LIVES filtradas pelo nome dos ARTISTAS ou dos seus estilos musicais;</li>
								<li>An&aacute;lise de pageviews; tempo m&eacute;dio de disp&ecirc;ndio por p&aacute;gina; n&uacute;mero de links e tags clicados, Google Analytics, e outras atividades correlatas.</li>
							</ol>
						</li>
						<li>USU&Aacute;RIO n&atilde;o &eacute; obrigado a fornecer qualquer informa&ccedil;&atilde;o (inclusive Dados Pessoais) ao SMIZE TV, no entanto, se n&atilde;o as fornecer ou bloquear os cookies (o USU&Aacute;RIO pode alterar as configura&ccedil;&otilde;es do navegador para recusar cookies), poder&aacute; n&atilde;o conseguir usufruir de todos os SERVI&Ccedil;OS que o SMIZE TV disponibiliza;
							<ol>
								<li>Um cookie &eacute; um trecho de informa&ccedil;&atilde;o armazenado localmente no computador ou dispositivo do USU&Aacute;RIO, e que cont&eacute;m informa&ccedil;&atilde;o acerca das atividades deste na Internet. A informa&ccedil;&atilde;o num cookie n&atilde;o cont&eacute;m qualquer informa&ccedil;&atilde;o pessoal identific&aacute;vel que o USU&Aacute;RIO envie para o SMIZE TV;</li>
								<li>SMIZE TV utiliza cookies de desempenho em seu SMIZE TV, que coleta informa&ccedil;&otilde;es (inclusive Dados Pessoais) as quais s&atilde;o utilizadas de forma anonimizada para verificar como os USU&Aacute;RIOS utilizam o SMIZE TV e como o SMIZE TV pode ser otimizado.</li>
							</ol>
						</li>
					</ol>
				</li>
				<li>FINALIDADES DO TRATAMENTO:
					<ol>
						<li>SMIZE TV poder&aacute; utilizar as informa&ccedil;&otilde;es (incluindo os Dados Pessoais) para:
							<ol>
								<li>Garantir que o conte&uacute;do do SMIZE TV seja apresentado da forma mais eficiente para o USU&Aacute;RIO e respectivo computador e/ou dispositivo m&oacute;vel de acesso, bem como para personalizar o SMIZE TV de acordo com as prefer&ecirc;ncias do USU&Aacute;RIO;</li>
								<li>Ajudar a realizar melhorias de car&aacute;ter geral no SMIZE TV e/ou nos SERVI&Ccedil;OS e permitir a cria&ccedil;&atilde;o de conta de USU&Aacute;RIO para acesso a &aacute;rea restrita do SMIZE TV;</li>
								<li>Fornecer, manter e melhorar os SERVI&Ccedil;OS, desenvolver novos recursos e/ou funcionalidades, prestar suporte ao USU&Aacute;RIO, desenvolver recursos de seguran&ccedil;a, autenticar USU&Aacute;RIOS e enviar as atualiza&ccedil;&otilde;es do servi&ccedil;o e/ou mensagens administrativas;</li>
								<li>Executar opera&ccedil;&otilde;es internas, incluindo, por exemplo, opera&ccedil;&otilde;es para evitar fraudes e abusos em rela&ccedil;&atilde;o aos SERVI&Ccedil;OS, solucionar problemas operacionais e/ou erros de software, executar an&aacute;lise de dados, testes e/ou pesquisas, bem como monitorar e analisar tend&ecirc;ncias de uso e atividade;</li>
								<li>Enviar comunicados que sejam de interesse dos USU&Aacute;RIOS, dentre os quais, informa&ccedil;&otilde;es sobre produtos, SERVI&Ccedil;OS, promo&ccedil;&otilde;es, not&iacute;cias e LIVES do SMIZE TV e empresas parceiras, quando admiss&iacute;vel e em conformidade com as leis locais aplic&aacute;veis;</li>
								<li>Cumprir e administrar quaisquer obriga&ccedil;&otilde;es decorrentes de acordos celebrados entre o SMIZE TV e os USU&Aacute;RIOS;</li>
								<li>Permitir que o USU&Aacute;RIO tenha acesso &agrave;s funcionalidades do SMIZE TV e/ou aos SERVI&Ccedil;OS;</li>
								<li>Contatar e notificar o USU&Aacute;RIO cadastrado no SMIZE TV acerca de modifica&ccedil;&otilde;es no SMIZE TV ou nos SERVI&Ccedil;OS;</li>
								<li>Analisar a forma como os USU&Aacute;RIOS utilizam o SMIZE TV, para fins de melhoria, marketing e investiga&ccedil;&otilde;es internas;</li>
								<li>Elaborar an&aacute;lises estat&iacute;sticas gerais, para identifica&ccedil;&atilde;o do perfil do USU&Aacute;RIO e desenvolvimento de conte&uacute;do pelo SMIZE TV;</li>
								<li>Permitir o funcionamento de funcionalidades pr&oacute;prias ou de terceiros, tais como bot&otilde;es de compartilhamento e inser&ccedil;&atilde;o de conte&uacute;do hospedado em outras PLATAFORMAs, aplicativos e/ou APPs (embedded);</li>
								<li>Informar o USU&Aacute;RIO a respeito de novidades no SMIZE TV, atrav&eacute;s de newsletter/e-mail marketing ou qualquer outra forma de comunica&ccedil;&atilde;o que venha a ser adotada pelo SMIZE TV;</li>
								<li>Cumprir legisla&ccedil;&atilde;o ou regulamenta&ccedil;&atilde;o aplic&aacute;vel.</li>
							</ol>
						</li>
						<li>As informa&ccedil;&otilde;es (inclusive Dados Pessoais) dos USU&Aacute;RIOS n&atilde;o ser&atilde;o revendidas a terceiros, nem utilizadas para fins de marketing;</li>
						<li>SMIZE TV mant&eacute;m os registros de acesso a aplica&ccedil;&otilde;es de internet sob sigilo, em ambiente controlado e de seguran&ccedil;a, pelo prazo de 6 (seis) meses, nos termos do artigo 15 do Marco Civil da Internet.</li>
					</ol>
				</li>
				<li>COMPARTILHAMENTO DE DADOS PESSOAIS:
					<ol>
						<li>SMIZE TV n&atilde;o divulga junto a terceiros qualquer informa&ccedil;&atilde;o (incluindo Dados Pessoais) fornecida pelo USU&Aacute;RIO atrav&eacute;s do SMIZE TV, exceto:
							<ol>
								<li>Para cumprir qualquer obriga&ccedil;&atilde;o legal (por exemplo, em virtude de ordem judicial, bem como em resposta &agrave; solicita&ccedil;&atilde;o de informa&ccedil;&otilde;es apresentada por autoridade competente), se entendermos que a divulga&ccedil;&atilde;o est&aacute; de acordo ou &eacute; exigida conforme as leis, regulamentos ou procedimentos processuais aplic&aacute;veis;</li>
								<li>Para fazer cumprir quaisquer condi&ccedil;&otilde;es de utiliza&ccedil;&atilde;o aplic&aacute;veis ao SMIZE TV, ou para fazer cumprir outros TERMOS DE USO referentes a SERVI&Ccedil;OS e/ou produtos que possam ser aplic&aacute;veis ou, ainda, para garantir adequa&ccedil;&atilde;o &agrave; documenta&ccedil;&atilde;o de APIs (Application Programming Interface) de outros aplicativos utilizados pelo SMIZE TV;</li>
								<li>No &acirc;mbito da venda de parte ou da totalidade do seu neg&oacute;cio ou ativos, ou como parte de qualquer reorganiza&ccedil;&atilde;o ou reestrutura&ccedil;&atilde;o do neg&oacute;cio, fus&atilde;o, cis&atilde;o ou incorpora&ccedil;&atilde;o, tomando sempre as medidas necess&aacute;rias para garantir que os Dados Pessoais dos USU&Aacute;RIOS continuem a ser protegidos. Nesse sentido, o USU&Aacute;RIO reconhece e aceita que os Dados Pessoais poder&atilde;o ser acessados, transferidos e/ou adquiridos como ativos por tais terceiros (inclusive nas fases preliminares como de Due Diligence);</li>
								<li>Para proteger os direitos, bens ou a seguran&ccedil;a do SMIZE TV, dos USU&Aacute;RIOS do SMIZE TV, ou de quaisquer terceiros, incluindo a troca de informa&ccedil;&atilde;o (incluindo Dados Pessoais) com outras empresas e organiza&ccedil;&otilde;es para fins de prote&ccedil;&atilde;o de fraude;</li>
								<li>Se os USU&Aacute;RIOS enviarem conte&uacute;do a algum f&oacute;rum aberto, como coment&aacute;rios em blogs, publica&ccedil;&otilde;es em m&iacute;dias sociais ou outros recursos dispon&iacute;veis atrav&eacute;s dos SERVI&Ccedil;OS e vis&iacute;veis ao p&uacute;blico em geral;</li>
								<li>Se o USU&Aacute;RIO autorizar, por exemplo, em casos de outros aplicativos ou SMIZE TV que se integram a nossa API (Application Programming Interface) ou SERVI&Ccedil;OS, ou aos quais nossa API ou SERVI&Ccedil;OS estejam integrados, ou, ainda, outro USU&Aacute;RIO, para fim de contato entre eles;</li>
								<li>Para fornecedores e outros prestadores de SERVI&Ccedil;OS que precisem acessar as informa&ccedil;&otilde;es para execu&ccedil;&atilde;o do trabalho em nosso nome;</li>
								<li>Se, mediante notifica&ccedil;&atilde;o, o USU&Aacute;RIO concordar em compartilh&aacute;-las;</li>
								<li>De forma anonimizada que n&atilde;o possa ser usada para identific&aacute;-lo;</li>
								<li>Com agentes da lei, autoridades governamentais ou terceiros, se entendermos que as a&ccedil;&otilde;es de algum USU&Aacute;RIO sejam inconsistentes em rela&ccedil;&atilde;o &agrave;s disposi&ccedil;&otilde;es desta POL&Iacute;TICA DE PRIVACIDADE ou TERMOS DE USO, ou para proteger os direitos, a propriedade ou a seguran&ccedil;a do SMIZE TV ou de terceiros.</li>
							</ol>
						</li>
						<li>Com exce&ccedil;&atilde;o das situa&ccedil;&otilde;es acima descritas, o SMIZE TV n&atilde;o divulgar&aacute; qualquer informa&ccedil;&atilde;o (incluindo Dados Pessoais) do USU&Aacute;RIO, exceto se devidamente autorizado.</li>
						<li>As informa&ccedil;&otilde;es (incluindo Dados Pessoais) cedidas pelo USU&Aacute;RIO e registradas devido ao acesso e uso do SMIZE TV (com exce&ccedil;&atilde;o ao conte&uacute;do de mensagens pessoais) servir&atilde;o como insumos para o mapeamento de informa&ccedil;&otilde;es de mercado e forma&ccedil;&atilde;o de estat&iacute;sticas do SMIZE TV, n&atilde;o sendo usada para associa&ccedil;&atilde;o de termos ou padr&otilde;es de busca na navega&ccedil;&atilde;o do SMIZE TV com USU&Aacute;RIOS individuais;</li>
					</ol>
				</li>
				<li>TRANSFER&Ecirc;NCIA INTERNACIONAL DOS DADOS PESSOAIS DO USU&Aacute;RIO:
					<ol>
						<li>SMIZE TV tem sua sede no Brasil e as informa&ccedil;&otilde;es (incluindo os Dados Pessoais) coletadas do USU&Aacute;RIO ser&atilde;o armazenadas pelo SMIZE TV em seus servidores e/ou nos servidores do provedor de SERVI&Ccedil;OS de cloud externo que o SMIZE TV contrata.</li>
						<li>Como parte dos SERVI&Ccedil;OS oferecidos ao USU&Aacute;RIO atrav&eacute;s do SMIZE TV, a informa&ccedil;&atilde;o (incluindo Dados Pessoais) que este fornece ao SMIZE TV poder&aacute; ser transferida e armazenada em pa&iacute;ses fora do Brasil. Estes pa&iacute;ses podem n&atilde;o possuir leis de prote&ccedil;&atilde;o de dados semelhantes &agrave;s do Brasil. Caso o SMIZE TV transfira, desta forma, informa&ccedil;&otilde;es e Dados Pessoais do USU&Aacute;RIO para fora do Brasil, ela tomar&aacute; as medidas razo&aacute;veis visando proteger os Dados Pessoais do USU&Aacute;RIO, conforme indicado nesta POL&Iacute;TICA DE PRIVACIDADE;</li>
						<li>Se o acesso e a utiliza&ccedil;&atilde;o do SMIZE TV ocorrer fora do Brasil, a informa&ccedil;&atilde;o (inclusive os Dados Pessoais) do USU&Aacute;RIO poder&aacute; ser transferida para o exterior, para possibilitar o funcionamento do SMIZE TV e para que os SERVI&Ccedil;OS lhe possam ser prestados e desde que com o consentimento do USU&Aacute;RIO;</li>
						<li>Dessa forma, ao enviar suas informa&ccedil;&otilde;es (inclusive Dados Pessoais) para o SMIZE TV, o USU&Aacute;RIO reconhece e concorda com o armazenamento, o tratamento e a transfer&ecirc;ncia (inclusive internacional) das mesmas, nos termos acima descritos.</li>
					</ol>
				</li>
				<li>DURA&Ccedil;&Atilde;O DO TRATAMENTO:
					<ol>
						<li>As informa&ccedil;&otilde;es (incluindo Dados Pessoais) fornecidas pelo USU&Aacute;RIO ser&atilde;o retidas durante todo o tempo que o USU&Aacute;RIO mantiver uma conta ativa no SMIZE TV ou conforme necess&aacute;rio ao SMIZE TV para prestar os SERVI&Ccedil;OS ao USU&Aacute;RIO e por um per&iacute;odo posterior adicional que permita: (i) ao USU&Aacute;RIO recuperar sua conta se decidir retornar ap&oacute;s a ter cancelado, (ii) ao SMIZE TV cumprir com contratos e/ou com a regulamenta&ccedil;&atilde;o ou legisla&ccedil;&atilde;o aplic&aacute;vel; (iii) ao SMIZE TV analisar suas opera&ccedil;&otilde;es e/ou os SERVI&Ccedil;OS prestados, sempre observada a legisla&ccedil;&atilde;o aplic&aacute;vel. Para obter mais informa&ccedil;&otilde;es sobre onde e por quanto tempo as informa&ccedil;&otilde;es (incluindo Dados Pessoais) s&atilde;o armazenadas, bem como sobre os direitos de exclus&atilde;o e de portabilidade, entre em contato atrav&eacute;s do e-mail indicado ao final dessa POL&Iacute;TICA DE PRIVACIDADE.</li>
						<li>Para cancelar sua conta no SMIZE TV, o USU&Aacute;RIO dever&aacute; submeter solicita&ccedil;&atilde;o no pr&oacute;prio SMIZE TV, o qual disp&otilde;e de uma ferramenta espec&iacute;fica para cancelamento.</li>
						<li>Na hip&oacute;tese de o USU&Aacute;RIO n&atilde;o conseguir realizar o cancelamento via SMIZE TV, este poder&aacute; entrar em contato por escrito atrav&eacute;s do e-mail indicado ao final dessa POL&Iacute;TICA DE PRIVACIDADE, para que seja realizada a devida desativa&ccedil;&atilde;o de sua conta no SMIZE TV.</li>
						<li>Se o USU&Aacute;RIO desativado desejar utilizar novamente o SMIZE TV, dever&aacute; efetuar novo cadastro ou entrar em contato por escrito atrav&eacute;s do e-mail indicado ao final dessa POL&Iacute;TICA DE PRIVACIDADE e solicitar a reativa&ccedil;&atilde;o do seu cadastro, o qual dever&aacute; ser atualizado.</li>
						<li>Quando o SMIZE TV n&atilde;o mais precisar processar as informa&ccedil;&otilde;es (incluindo Dados Pessoais) do USU&Aacute;RIO nos termos desta POL&Iacute;TICA DE PRIVACIDADE ou, ap&oacute;s a solicita&ccedil;&atilde;o espec&iacute;fica do USU&Aacute;RIO para excluir tais informa&ccedil;&otilde;es, o SMIZE TV excluir&aacute; ou anonimizar&aacute; tais informa&ccedil;&otilde;es, sempre observada a legisla&ccedil;&atilde;o aplic&aacute;vel.</li>
					</ol>
				</li>
				<li>DIREITOS DOS USU&Aacute;RIOS:
					<ol>
						<li>SMIZE TV tomar&aacute; todas as medidas cab&iacute;veis, de acordo com suas obriga&ccedil;&otilde;es legais, para atualizar e corrigir as informa&ccedil;&otilde;es (inclusive Dados Pessoais) que tenham sido submetidas pelos USU&Aacute;RIOS atrav&eacute;s do SMIZE TV, que se encontrem em sua posse;</li>
						<li>USU&Aacute;RIO ter&aacute; o direito de acessar, modificar, corrigir e eliminar os Dados Pessoais fornecidos quando de sua ades&atilde;o ao SMIZE TV. No caso de atualiza&ccedil;&atilde;o de qualquer informa&ccedil;&atilde;o (inclusive Dados Pessoais), o SMIZE TV poder&aacute; manter uma c&oacute;pia das informa&ccedil;&otilde;es anteriormente fornecidas nos arquivos e respectivos registros sobre uso do sistema para fins de auditoria interna e/ou legal/regulat&oacute;ria;</li>
						<li>USU&Aacute;RIO dever&aacute; sempre manter suas informa&ccedil;&otilde;es (inclusive Dados Pessoais) atualizadas, sendo certo que, no caso de incorre&ccedil;&atilde;o das mesmas, o SMIZE TV poder&aacute; atualiz&aacute;-las e/ou exclu&iacute;-las &ndash; exceto no caso de necessidade de manuten&ccedil;&atilde;o para fins legais e/ou regulat&oacute;rios. Na atualiza&ccedil;&atilde;o das informa&ccedil;&otilde;es (inclusive Dados Pessoais), poder&aacute; ser solicitada a confirma&ccedil;&atilde;o da identidade do USU&Aacute;RIO;</li>
						<li>A LGPD confere aos USU&Aacute;RIOS, dentre outros, o direito de confirmar a exist&ecirc;ncia de tratamento de seus Dados Pessoais em poder do SMIZE TV. Este direito poder&aacute; ser exercido nos termos da LGPD. Se o USU&Aacute;RIO pretender consultar os detalhes acerca de qualquer Dado Pessoal que o SMIZE TV det&eacute;m sobre si, deve entrar em contato atrav&eacute;s do e-mail indicado ao final dessa POL&Iacute;TICA DE PRIVACIDADE.</li>
						<li>USU&Aacute;RIO poder&aacute; ainda, na extens&atilde;o permitida pela legisla&ccedil;&atilde;o aplic&aacute;vel, contatar o SMIZE TV para exerceu seu direito de: (i) portar os Dados Pessoais para outro fornecedor de servi&ccedil;o ou produto, observados segredos comercial e industrial; (ii) obter informa&ccedil;&otilde;es acerca do compartilhamento de Dados Pessoais; (iii) obter informa&ccedil;&otilde;es sobre a possibilidade de n&atilde;o fornecer consentimento e as consequ&ecirc;ncias disso; (iv) revogar o consentimento, nos termos da legisla&ccedil;&atilde;o aplic&aacute;vel. Para tanto, o USU&Aacute;RIO dever&aacute; entrar em contato com o encarregado de prote&ccedil;&atilde;o de dados do SMIZE TV atrav&eacute;s do e-mail indicado ao final desta POL&Iacute;TICA DE PRIVACIDADE e o SMIZE TV responder&aacute; dentro de prazo razo&aacute;vel e de acordo com a legisla&ccedil;&atilde;o aplic&aacute;vel.</li>
					</ol>
				</li>
				<li>MEDIDAS DE SALVAGUARDA E SEGURAN&Ccedil;A:
					<ol>
						<li>As informa&ccedil;&otilde;es (inclusive Dados Pessoais) da conta dos USU&Aacute;RIOS s&atilde;o protegidas por uma senha para sua seguran&ccedil;a e privacidade. Os USU&Aacute;RIOS precisam se certificar de que n&atilde;o haja acesso n&atilde;o autorizado &agrave; sua conta e aos seus Dados Pessoais, selecionando e protegendo a sua senha de forma adequada e limitando o acesso ao seu aparelho m&oacute;vel, realizando logoff do SMIZE TV quando tiver terminado de acessar sua conta. O USU&Aacute;RIO ser&aacute; &uacute;nica e exclusivamente respons&aacute;vel por todas as atividades que ocorram em sua conta e concorda em notificar o SMIZE TV imediatamente no caso de qualquer uso n&atilde;o autorizado de sua conta ou qualquer outra viola&ccedil;&atilde;o de seguran&ccedil;a;</li>
						<li>Se o SMIZE TV tomar conhecimento de qualquer viola&ccedil;&atilde;o &agrave; seguran&ccedil;a pr&oacute;pria ou das empresas de hospedagem, incluindo qualquer hacker ou outro &quot;break-in&quot;, o USU&Aacute;RIO ser&aacute; notificado de tal viola&ccedil;&atilde;o e receber&aacute; informa&ccedil;&otilde;es referentes &agrave; natureza e extens&atilde;o da viola&ccedil;&atilde;o e eventuais dados pessoais comprometidos;</li>
						<li>Esta POL&Iacute;TICA DE PRIVACIDADE aborda apenas o uso e divulga&ccedil;&atilde;o de informa&ccedil;&otilde;es (inclusive Dados Pessoais) coletadas pelo SMIZE TV. Se o USU&Aacute;RIO divulgar suas informa&ccedil;&otilde;es (inclusive Dados Pessoais) a terceiros atrav&eacute;s de PLATAFORMAs, aplicativos e/ou APPs em toda a internet, regras diferentes podem ser aplicadas a a referida utiliza&ccedil;&atilde;o e/ou divulga&ccedil;&atilde;o;</li>
						<li>Note que informa&ccedil;&otilde;es (incluindo Dados Pessoais) que trafegam em uma rede aberta, como internet ou e-mail, podem ser acessadas/capturadas por qualquer pessoa. O SMIZE TV n&atilde;o garante a confidencialidade de qualquer comunica&ccedil;&atilde;o e/ou material transmitido nesse tipo de rede aberta. Quando exp&otilde;e qualquer informa&ccedil;&atilde;o (inclusive Dados Pessoais) em uma rede aberta, o USU&Aacute;RIO deve ter em mente que a informa&ccedil;&atilde;o (inclusive Dados Pessoais) est&aacute; potencialmente acess&iacute;vel a terceiros e, consequentemente, poder&aacute; ser coletada e usada por terceiros sem seu consentimento. Os Dados Pessoais do USU&Aacute;RIO podem ainda ser perdidos durante a transmiss&atilde;o. O SMIZE TV n&atilde;o ser&aacute; respons&aacute;vel por quaisquer perdas e/ou danos eventuais sofridos pelo USU&Aacute;RIO em fun&ccedil;&atilde;o da transfer&ecirc;ncia das informa&ccedil;&otilde;es (incluindo Dados Pessoais) via internet. Em caso de d&uacute;vida, o USU&Aacute;RIO n&atilde;o dever&aacute; utilizar determinada rede, utilizando outros meios de comunica&ccedil;&atilde;o se considerar necess&aacute;rio e prudente por raz&otilde;es de seguran&ccedil;a;</li>
						<li>SMIZE TV tomar&aacute; todas as medidas f&iacute;sicas, t&eacute;cnicas e administrativas razo&aacute;veis para proteger a confidencialidade, integridade e disponibilidade dos Dados Pessoais fornecidos pelo USU&Aacute;RIO contra acesso, utiliza&ccedil;&atilde;o, altera&ccedil;&atilde;o, divulga&ccedil;&atilde;o, transfer&ecirc;ncia, perda ou destrui&ccedil;&atilde;o n&atilde;o autorizados, acidentais ou il&iacute;citos. O SMIZE TV emprega as mais recentes tecnologias e software de encripta&ccedil;&atilde;o para manter os Dados Pessoais do USU&Aacute;RIO em seguran&ccedil;a e implementa normas de seguran&ccedil;a rigorosas para evitar qualquer acesso n&atilde;o autorizado. O acesso aos Dados Pessoais coletados &eacute; restrito a pessoas autorizadas pelo SMIZE TV e que precisam ter conhecimento do mesmo, as quais tamb&eacute;m est&atilde;o obrigadas a observar esta POL&Iacute;TICA DE PRIVACIDADE.</li>
					</ol>
				</li>
				<li>MODIFICA&Ccedil;&Otilde;ES DA POL&Iacute;TICA DE PRIVACIDADE:
					<ol>
						<li>SMIZE TV poder&aacute; alterar esta POL&Iacute;TICA DE PRIVACIDADE de tempos em tempos, sendo que a vers&atilde;o atual estar&aacute; sempre dispon&iacute;vel no SMIZE TV para consulta peri&oacute;dica do USU&Aacute;RIO. No caso de o SMIZE TV realizar mudan&ccedil;as na forma como usa os Dados Pessoais, ir&aacute; notificar os USU&Aacute;RIOS acerca das mudan&ccedil;as para que este possa aceit&aacute;- las ou rejeit&aacute;-las;</li>
						<li>O acesso ao e &agrave; utiliza&ccedil;&atilde;o do SMIZE TV ap&oacute;s a data efetiva de qualquer altera&ccedil;&atilde;o postada anteriormente caracterizar&aacute; aceita&ccedil;&atilde;o t&aacute;cita da nova vers&atilde;o da POL&Iacute;TICA DE PRIVACIDADE. Por esta raz&atilde;o, o SMIZE TV encoraja seus USU&Aacute;RIOS a rever esta POL&Iacute;TICA DE PRIVACIDADE sempre que for utilizar o SMIZE TV.</li>
					</ol>
				</li>
				<li>OUTRAS PLATAFORMAS, APLICATIVOS E/OU WEBAPPS:
					<ol>
						<li>SMIZE TV cont&eacute;m links e refer&ecirc;ncias a outras PLATAFORMAs, aplicativos e/ou APPs. Esta POL&Iacute;TICA DE PRIVACIDADE n&atilde;o se aplica a essas outras PLATAFORMAs, aplicativos e/ou APPs.</li>
						<li>SMIZE TV n&atilde;o se responsabilizar&aacute; pelas pol&iacute;ticas e pr&aacute;ticas de privacidade e prote&ccedil;&atilde;o de dados pessoais de PLATAFORMAs, aplicativos e/ou APPs de terceiros que n&atilde;o o SMIZE TV, ainda que o acesso aos mesmos seja realizado atrav&eacute;s do SMIZE TV. Recomendamos aos USU&Aacute;RIOS que consultem as pol&iacute;ticas de privacidades e os TERMOS DE USO de cada PLATAFORMA, aplicativo e/ou APP que visitarem antes de fornecer quaisquer informa&ccedil;&otilde;es, e que contatem o respectivo titular ou operador se tiverem alguma d&uacute;vida ou quest&atilde;o;</li>
						<li>Adicionalmente, se o USU&Aacute;RIO tiver chegado ao SMIZE TV atrav&eacute;s de uma PLATAFORMA, aplicativo e/ou APP de terceiros, o SMIZE TV n&atilde;o se responsabilizar&aacute; pelas pr&aacute;ticas e pol&iacute;ticas de privacidade e prote&ccedil;&atilde;o de dados pessoais implementadas pelos titulares ou operadores dos referidos em quest&atilde;o, e recomenda aos USU&Aacute;RIOS que consultem a POL&Iacute;TICA DE PRIVACIDADE e os TERMOS DE USO daqueles e que contatem o respectivo titular ou operador se tiverem alguma d&uacute;vida ou quest&atilde;o.</li>
					</ol>
				</li>
				<li>BEACONS DA WEB:
					<ol>
						<li>Em circunst&acirc;ncias limitadas, faculta-se o uso de web beacons para coletar informa&ccedil;&otilde;es an&ocirc;nimas, n&atilde;o-pessoais sobre a utiliza&ccedil;&atilde;o do SMIZE TV para patrocinadores e anunciantes selecionados; tal qual o uso de e-mails, para promo&ccedil;&otilde;es especiais ou newsletters.</li>
						<li>Web Beacons s&atilde;o pequenos arquivos de imagem gr&aacute;fica embutida em uma p&aacute;gina web ou e-mail que proporcionam uma presen&ccedil;a na p&aacute;gina web ou e-mail e enviam de volta para o servidor hospedeiro as informa&ccedil;&otilde;es do browser do USU&Aacute;RIO.</li>
						<li>As informa&ccedil;&otilde;es coletadas pelos web beacons permite o SMIZE TV monitorar estatisticamente quantas pessoas est&atilde;o usando a PLATAFORMA, em quais dispositivos e com quais patrocinadores selecionados e anunciantes, tal qual nos informa a taxa de abertura e clique no e-mail de comunica&ccedil;&atilde;o.</li>
					</ol>
				</li>
				<li>INFORMA&Ccedil;&Atilde;O P&Uacute;BLICA:
					<ol>
						<li>Qualquer informa&ccedil;&atilde;o do USU&Aacute;RIO revelada em uma postagem de avalia&ccedil;&atilde;o ou discuss&atilde;o online (em f&oacute;runs e m&iacute;dias sociais, por exemplo) &eacute; intencionalmente aberta ao p&uacute;blico e n&atilde;o &eacute; de qualquer maneira privada.</li>
					</ol>
				</li>
				<li>DESATIVA&Ccedil;&Atilde;O DA CONTA:
					<ol>
						<li>Desativar uma conta &eacute; o mesmo que dizer para n&atilde;o se excluir informa&ccedil;&otilde;es, pois o USU&Aacute;RIO pode querer reativar a respectiva conta futuramente. O USU&Aacute;RIO pode desativar a conta na p&aacute;gina de &ldquo;configura&ccedil;&otilde;es da conta&rdquo;.</li>
						<li>Acaso queira a exclus&atilde;o permanente de todos os dados existentes, reiteramos que realize o pedido no e-mail contato@smize.tv.</li>
					</ol>
				</li>
				<li>CONTATO:
					<ol>
						<li>USU&Aacute;RIO poder&aacute; entrar em contato com o SMIZE TV a qualquer momento e compartilhar suas opini&otilde;es e/ou d&uacute;vidas sobre as pr&aacute;ticas de prote&ccedil;&atilde;o de dados pessoais ou sobre qualquer quest&atilde;o relacionada aos Dados Pessoais e/ou ao SMIZE TV. Para isso, basta entrar em contato com o encarregado de prote&ccedil;&atilde;o de dados pessoais atrav&eacute;s do e-mail contato@smize.tv . O atendimento ao consumidor &eacute; realizado de segunda a sexta, exceto em feriados, das 8:00h &agrave;s 18:00h.</li>
					</ol>
				</li>
			</ol>

			<p>&nbsp;</p>

			<p>BASCODE DESENVOLVIMENTO DE APLICATIVOS LTDA.</p>

			<p>&nbsp;</p>

			<p>Nome Fantasia: SMIZE TV</p>

			<p>CNPJ n&ordm; 42.377.335/0001-73</p>

			<p>Rua MARIA CURUPAITI, 19 - Bairro: Vila Ester (Zona Norte), SAO PAULO - SP CEP 02452000. </p>

			<p>E-mail para: contato@smize.tv.</p>

			<p>&nbsp;</p>

			<p>Copyright (&copy;) 2023 .</p>

			<p>Todos os Direitos Reservados.</p>

			<p>&nbsp;</p>

			<p>&nbsp;</p>


		</div>

	);
}

export default PgaPoliticas;