import axios from 'axios';

const token = ""
const urltrailer = "https://api.judah.tv/api/listtrailerx"

const urlcanais = "https://api.judah.tv/api/listtvx/"



const urlshorts = "https://api.judah.tv/api/listshortsx/"


//const getTrailer = axios.get(urltrailer, {
//  headers: {
//  Authorization: `Bearer ${token}`
// } })



const getTrailer = axios.get(urltrailer)



const getShorts = axios.get(urlshorts)

const getCanais = (lista) => axios.get(urlcanais + lista)




export default { getTrailer, getShorts, getCanais }