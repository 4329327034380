import React from 'react'

const fotourl = 'https://api.judah.tv/uploads'

function Canal({ item }) {
  return (
    <div className=' hover:scale-110 transition-all duration-100 ease-in' >

      <img src={`${fotourl}/${item.fototv}.jpg`} className='w-[160px] h-[90px]  md:w-[288px]   md:h-[162px] object-cover rounded-lg hover:border-[4px] border-teal-300  cursor-pointer' />
      <h2 className='w-[160px]  md:w-[288px] text-[16px] md:text-[24px] text-white font-bold mt-2 '>{item.username}</h2>
    </div>
  )
}

export default Canal