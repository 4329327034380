import React from 'react'

const fotourl = 'https://api.judah.tv/uploads'

function Shorts({item}) {
  return (
    <div className=' hover:scale-110 transition-all duration-100 ease-in' >
 
    <img  src={`${fotourl}/${item.foto}.jpg`} className='w-[84px] h-[112px]  md:w-[120px]   md:h-[160px] object-cover rounded-lg hover:border-[4px] border-teal-300  cursor-pointer' />
    <h2 className='w-[84px]  md:w-[120px] text-[20px] text-white  font-bold mt-2'>{item.nome}</h2> 
</div>
  )
}

export default Shorts