import React, { useEffect } from 'react';
import { useParams } from "react-router-dom";

import { data_user } from '../DataAPI/datauser'

import './PgaIngressoTV.css';

function PgaIngressoTV() {

    let { liveId } = useParams();

    useEffect(
        () => {
            _carregatela()
        }, [])

    async function _carregatela() {



        console.log('valida tv', liveId)

        const resposta = await data_user.autorizatv(liveId)


        if (resposta) {

            console.log(resposta, 'valida tv')

        }


    }

    return (
        <div class="xtudol" >



            <h1 style={{ textAlign: 'center' }}>Validar Ingresso</h1>

            <br></br>
            <p>Ingresso Validado</p>


            <p>&nbsp;</p>


        </div>

    );
}

export default PgaIngressoTV;