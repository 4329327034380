
import React, { useEffect, useState, useContext, useRef } from 'react';

import './PgHome.css'
import Slider from '../Componets/Slider'
import CategoriaLista from '../Componets/CategoriaLista'


import { validarME } from '../DataAPI/apiMe'


import { MeuContexto } from "../App"


function PgHome() {

  const [isloading, setisloading] = useState(true);
  const { setlogado, setusername } = useContext(MeuContexto)

  useEffect(
    () => {
      _carregatela()
    }, [])

  async function _carregatela() {


    const me = await validarME.me()
    if (me) {

      setusername(me.username)

      setisloading(false)

      setlogado(true)


    } else {

      setisloading(false)
      setlogado(false)

    }


  }


  return (

    <div className="font-body">
      <Slider></Slider>
      <CategoriaLista></CategoriaLista>

    </div>


  )
}

export default PgHome
