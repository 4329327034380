import React from 'react';

import './CompModalIugu.css';

import { MdClose } from "react-icons/md";

function Modal({ close, show, children, title }) {

    return (
        <div className={show ? "modal1 display-block1" : "modal1 display-none1"}>

            <section className="modal-main1">
                <div className="modal-close1">
                    <MdClose onClick={close} color="#F2F2F2" size={24} />

                    <h2>{title}</h2>

                    <MdClose onClick={close} color={"#ffffff"} size={24} />

                </div>

                {children}

            </section>
        </div>
    );
}

export default Modal;