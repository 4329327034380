import React from 'react';




function PgAssinatura() {

  const plans = [
    {
      id: 1,
      tipo: 'Mensal',
      preco: 'R$ 29,90',
      descricao: 'Acesso por 30 dias ao conteúdo completo.',
    },
    {
      id: 2,
      tipo: 'Trimestral',
      preco: 'R$ 79,90',
      descricao: 'Acesso por 90 dias com um desconto especial.',
    },
    {
      id: 3,
      tipo: 'Anual',
      preco: 'R$ 299,90',
      descricao: 'Acesso por um ano inteiro ao menor custo por mês.',
    },
  ];

  return (   <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Planos de Assinatura</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {plans.map((plan) => (
          <div
            key={plan.id}
            className="bg-white rounded-lg shadow-md p-6 flex flex-col items-center text-center"
          >
            <h2 className=" text-black text-xl font-semibold mb-2">{plan.tipo}</h2>
            <p className=" text-black text-3xl font-bold mb-2">{plan.preco}</p>
            <p className="text-gray-600 mb-4">{plan.descricao}</p>
            <button className="bg-blue-500 text-white font-bold py-2 px-4 rounded hover:bg-blue-600 transition duration-300">
              Assinar
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}

export default PgAssinatura;