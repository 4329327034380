import React, { useEffect, useState, useContext, useRef } from 'react'
import CanaisLista from './CanaisLista'

import ShortsLista from './ShortsLista'

import CompActive from '../Componentes/CompActive'

import GlobalApi from '../Services/GlobalApi'

function CategoriaLista() {


  const [isloading, setisloading] = useState(true);

  const [lista1, setLista1] = useState([])

  const [lista2, setLista2] = useState([])

  const [lista3, setLista3] = useState([])

  const [lista4, setLista4] = useState([])

  const [lista5, setLista5] = useState([])


  useEffect(
    () => {
      _carregatela()
    }, [])


  async function _carregatela() {



    const resposta1 = await GlobalApi.getCanais("Ao Vivo")
    if (resposta1) {
      setLista1(resposta1.data)
    }
    const resposta2 = await GlobalApi.getCanais("Teatro")
    if (resposta2) {
      setLista2(resposta2.data)
    }
    const resposta3 = await GlobalApi.getCanais("Música")
    if (resposta3) {
      setLista3(resposta3.data)
    }
    const resposta4 = await GlobalApi.getCanais("Shows")
    if (resposta4) {
      setLista4(resposta4.data)
    }
    const resposta5 = await GlobalApi.getShorts
    if (resposta5) {
      setLista5(resposta5.data)
    }






    setisloading(false)

  }

  return (
    <div>
      {(isloading) ? (<>
        <div style={{
          width: '4rem',
          height: '4rem',
          margin: '0 auto',
          padding: '.4rem',
        }}>
          <CompActive />
        </div>
      </>) : (<>

        {(lista1.length !== 0) ? <>

          <div className='p-2 md:p-4' >
            <h2 className='ml-2 text-[24px] md:text-[32px] text-violet-300 font-bold '>Ao Vivo</h2>
            <CanaisLista canaislist={lista1} />

          </div>

        </> : <></>}

        {(lista2.length !== 0) ? <>

          <div className='p-2 md:p-4' >
            <h2 className='ml-2 text-[24px] md:text-[32px] text-violet-300 font-bold '>Teatro</h2>
            <CanaisLista canaislist={lista2} />

          </div>

        </> : <></>}


        {(lista3.length !== 0) ? <>

          <div className='p-2 md:p-4' >
            <h2 className='ml-2 text-[24px] md:text-[32px] text-violet-300 font-bold '>Música</h2>
            <CanaisLista canaislist={lista3} />

          </div>

        </> : <></>}




        {(lista4.length !== 0) ? <>

          <div className='p-2 md:p-4' >
            <h2 className='ml-2 text-[24px] md:text-[32px] text-violet-300 font-bold '>Shows</h2>
            <CanaisLista canaislist={lista4} />

          </div>

        </> : <></>}

        {(lista5.length !== 0) ? <>

          <div className='p-2 md:p-4' >
            <h2 className='ml-2 text-[24px] md:text-[32px] text-violet-300 font-bold '>Curtas</h2>
            <ShortsLista shortslist={lista5} />

          </div>

        </> : <></>}




      </>)}



    </div>
  )
}

export default CategoriaLista