import React, { useState, useEffect, useRef } from 'react';

import io from 'socket.io-client';

import ChatItem from './CompChatItem';

import "./CompChat.css"

import { global } from '../DataAPI/global'

let socket = null
let vrentrou = false
const Chat = ({ onPress, seArtista, ativo, onDenunciar, user, room }) => {

    const [chatMessage, setchatMessage] = useState('')
    const [listamensagens, setlistamensagens] = useState([])

    const [didMount, setDidMount] = useState(false);

    const meuuser = user

    const ref = useRef();
    const scroll = () =>
        ref && ref.current && ref.current.scrollIntoView({ behavior: "smooth" });


    useEffect(() => {
        setDidMount(true);
        return () => setDidMount(false);
    }, [])


    useEffect(() => {


        if (ativo > 0) {

            if (!socket) {
                global.mensagens = []
                ///  console.log(meuuser)
                socket = io(global.brl.REACT_APP_CHAT);



                socket.connect()


            }
            if (meuuser) {
                ///    console.log(meuuser)
                socket.emit('scjoin', {
                    room: room,
                    chatMessage: `join`,
                    artist: seArtista,
                    username: meuuser.username,
                    id: meuuser._id,
                    foto: meuuser.fotoid
                })


                socket.on('scnovamsgsala', data => {
                    ///    console.log(data)
                    global.mensagens = [data, ...global.mensagens.slice(0, 64)]

                    setlistamensagens(global.mensagens)
                    scroll()
                    onPress()

                })

                socket.on('scentrou', data => {

                    /// console.log(data, '------------------scentrou')



                    global.validar = data
                    if (!vrentrou) {
                        vrentrou = true
                        global.mensagens = [data, ...global.mensagens.slice(0, 64)]

                        setlistamensagens(global.mensagens)
                    }

                    ///  console.log(global.validar, 'chat')

                    onPress()

                })

                socket.on('scsaiu', data => {

                    global.validar = data
                    onPress()
                    ///   console.log(data, '------------------saiu')

                })


            }

        }

        if (ativo === 0) {


            if (socket) {

                socket.emit('scleave')
                socket.disconnect()
                socket = null
                vrentrou = false
            }



        }


    }, [ativo])




    function _enviar(e) {

        e.preventDefault();
        if (chatMessage.length) {

            socket.emit('scnovamsg', {
                room: room,
                chatMessage: chatMessage.substring(0, 140),
                artist: seArtista,
                username: meuuser.username,
                id: meuuser._id,
                foto: meuuser.fotoid,
            });
            setchatMessage('')

        }
    }

    if (!didMount) {
        if (socket) {
            socket.emit('scleave')
            socket.disconnect()
            socket = null
            vrentrou = false
        }

        return null;
    }

    return (
        <div className="container">

            <ul style={{ listStyle: 'none' }} className="chat-container">

                {listamensagens.map((item) =>

                    <li key={item._id} >

                        <ChatItem item={item} />

                    </li>

                ).reverse()}
                <li ref={ref}></li>

            </ul>


            <form className={"uform"} onSubmit={(e) => { _enviar(e) }} autoComplete="off">

                <input
                    className={"uinput"}
                    autoComplete="off"
                    placeholder="Enviar Mensagem"
                    name="chatMessage"
                    type="text"
                    value={chatMessage}
                    onChange={e => setchatMessage(e.target.value)} />


                <button className="btsalvar chath" type="submit" value="Submit">Enviar</button>


            </form>

        </div>

    );
}



export default Chat;
