import React from 'react'

function HeaderItem({ name, Icon }) {
    return (
        <div className='text-violet-300 flex items-center gap-2 text-[18px] font-semibold cursor-pointer hover:underline underline-offset-8 mb-2'>
            <Icon />
            <h2 className=''>{name}</h2>

        </div>
    )
}

export default HeaderItem